var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-main",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title":
            this.type == "edit"
              ? _vm.$t("cip.plat.sys.serialNumber.title.editHeadTitle")
              : this.type == "view"
              ? _vm.$t("cip.plat.sys.serialNumber.title.viewHeadTitle")
              : _vm.$t("cip.plat.sys.serialNumber.title.addHeadTitle"),
        },
        on: {
          "head-save": function ($event) {
            return _vm.submitbtn()
          },
          "head-save-back": function ($event) {
            return _vm.submitbtn("back")
          },
          "head-cancel": _vm.close,
        },
      }),
      _c("form-layout", {
        ref: "formLayout",
        attrs: { group: _vm.group, dataForm: _vm.form },
        on: {
          "update:group": function ($event) {
            _vm.group = $event
          },
          "update:dataForm": function ($event) {
            _vm.form = $event
          },
          "update:data-form": function ($event) {
            _vm.form = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }